.pc {
  display: block;
}

.sp {
  display: none !important;
}

#responsive_flg {
  display: none;
}

/* ------------------------------------------------------------*/
/* sp 〜767
------------------------------------------------------------*/

@media screen and (max-width: 767px) {
	
}// media