@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");
@import url(../fonts/icomoon/style.css);
@import url("https://use.typekit.net/iqf0tiu.css");
@-webkit-keyframes hover {
  30% {
    -webkit-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -moz-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -ms-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -o-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg); }
  100% {
    -webkit-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -moz-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -ms-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -o-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg); } }

@-moz-keyframes hover {
  30% {
    -webkit-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -moz-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -ms-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -o-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg); }
  100% {
    -webkit-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -moz-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -ms-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -o-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg); } }

@keyframes hover {
  30% {
    -webkit-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -moz-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -ms-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    -o-transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg);
    transform: translate3d(0, -12px, 20px) scale(1) rotateX(-5deg); }
  100% {
    -webkit-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -moz-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -ms-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -o-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg); } }

@-webkit-keyframes out {
  0% {
    -webkit-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -moz-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -ms-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -o-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg); } }

@-moz-keyframes out {
  0% {
    -webkit-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -moz-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -ms-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -o-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg); } }

@keyframes out {
  0% {
    -webkit-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -moz-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -ms-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    -o-transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg);
    transform: translate3d(0, -10px, 15px) scale(1) rotateX(-2deg); } }

/* CSS Document */
*, :before, :after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s linear; }

ul, ol {
  list-style: none; }

h1, h2, h3, h4, ul, ol, dl, dd, p, hr, figure {
  margin: 0;
  padding: 0; }

header, nav, main, footer, section, aside {
  display: block; }

strong {
  font-weight: normal; }

form {
  margin-bottom: 0; }

img {
  vertical-align: bottom; }

::selection {
  color: #fff;
  background: #00aebb; }

::-moz-selection {
  color: #fff; }

input::selection,
textarea::selection {
  color: #221814;
  background: #dbdcdc; }

input[type=text],
button {
  -webkit-appearance: none;
  appearance: none;
  border: none; }

.collection main {
  overflow: hidden; }

.noLink {
  text-decoration: none !important;
  cursor: default; }

p {
  line-height: 1.6;
  font-size: 1.4rem;
  text-align: justify; }

.ar {
  text-align: right; }

i {
  font-style: normal; }

.fr {
  float: right; }

/* !!------------------------------------ */
/* !! 共通css */
html {
  font-size: 62.5%; }

body {
  margin: 0 auto;
  padding: 0;
  color: #231815;
  min-width: 1280px;
  width: 100%;
  background: #F8FAFA;
  font-family: "Hiragino Sans","Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }

@media screen and (min-width: 1200px) {
  overflow-x: hidden; }

main {
  background: #F8FAFA;
  width: 100% !important; }
  main > section {
    position: relative;
    background: #fff;
    padding: 5%;
    z-index: 2;
    padding-left: calc(5% + 80px); }
    main > section:nth-of-type(2n) {
      background: #F8FAFA; }
    main > section > h2 {
      font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      position: relative;
      margin-bottom: 50px; }
      main > section > h2:before {
        content: "";
        height: 1.5px;
        width: 500px;
        display: block;
        background: #000;
        position: absolute;
        left: -510px;
        top: 6px; }
      main > section > h2 strong {
        line-height: 1;
        font-family: "bebas-neue", sans-serif;
        display: block;
        font-size: 4.5rem;
        margin-top: 13px; }
    main > section .toggle {
      cursor: pointer;
      width: 106px;
      height: 30px;
      position: absolute;
      top: 84px;
      right: 50px;
      padding-right: 20px;
      text-align: center;
      border-left: 1px solid #000; }
      main > section .toggle:before {
        content: "VIEW ALL";
        line-height: 30px; }
      main > section .toggle i {
        transition: 100ms transform;
        width: 20px;
        height: 30px;
        position: absolute;
        right: 0;
        top: 0;
        background-image: url(../img/viewAll_1.svg);
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-position: center center; }
      main > section .toggle:active i {
        -webkit-transform: scale(1) !important;
        -moz-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        -o-transform: scale(1) !important;
        transform: scale(1) !important; }
      main > section .toggle:hover i {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2); }
      main > section .toggle.toggled:before {
        content: "VIEW SMALL"; }
      main > section .toggle.toggled i {
        background-image: url(../img/viewAll_2.svg);
        background-repeat: no-repeat;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-position: center center; }

header#menu {
  z-index: 1;
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

ul.lang-menu {
  position: absolute;
  font-size: 0;
  white-space: nowrap;
  position: absolute;
  right: 120px;
  top: 40px; }
  ul.lang-menu li {
    font-size: 14px;
    line-height: 40px;
    display: inline-block;
    margin-left: 15px; }

.idx {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

/* !!------------------------------------ */
/* !! メインメニュー */
canvas {
  display: block;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: .5;
  transition: 500ms;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  mix-blend-mode: multiply; }
  body.menu_on canvas {
    opacity: 1;
    z-index: 3;
    mix-blend-mode: normal;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); }

#mainMenu {
  position: fixed;
  display: block;
  height: 100vh;
  width: calc(100vw - 384px);
  min-width: 880px;
  top: 0;
  right: 0;
  z-index: 10;
  pointer-events: none;
  /*
	&.project:before{
		@include bg2;
	}
	
	body.menu_on &:before{
		opacity: 1;
		@include transform(translateX(0%));
	}
*/ }
  body.side_small #mainMenu {
    width: calc(100vw - 80px); }
  #mainMenu:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 200ms;
    -webkit-transform: translateX(30%);
    -moz-transform: translateX(30%);
    -ms-transform: translateX(30%);
    -o-transform: translateX(30%);
    transform: translateX(30%); }
  #mainMenu #btn {
    width: 40px;
    height: 24px;
    position: absolute;
    top: 50px;
    right: 50px;
    cursor: pointer;
    z-index: 3;
    pointer-events: auto;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg); }
    #mainMenu #btn i {
      transition: 300ms cubic-bezier(0, 0.96, 0.7, 1.2);
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background: #fff;
      transform-origin: left center;
      -webkit-transform: translateY(0) rotate(0) scaleX(1);
      -moz-transform: translateY(0) rotate(0) scaleX(1);
      -ms-transform: translateY(0) rotate(0) scaleX(1);
      -o-transform: translateY(0) rotate(0) scaleX(1);
      transform: translateY(0) rotate(0) scaleX(1); }
    #mainMenu #btn ._1 {
      top: 0;
      left: 0; }
    #mainMenu #btn ._2 {
      top: 50%;
      left: 0;
      margin-top: -1px; }
    #mainMenu #btn ._3 {
      bottom: 0;
      left: 0;
      transition-delay: 50ms; }
    body.menu_on #mainMenu #btn i {
      background: #000; }
    body.menu_on #mainMenu #btn ._1 {
      -webkit-transform: translateY(11px) rotate(-45deg) scaleX(0.175);
      -moz-transform: translateY(11px) rotate(-45deg) scaleX(0.175);
      -ms-transform: translateY(11px) rotate(-45deg) scaleX(0.175);
      -o-transform: translateY(11px) rotate(-45deg) scaleX(0.175);
      transform: translateY(11px) rotate(-45deg) scaleX(0.175); }
    body.menu_on #mainMenu #btn ._3 {
      -webkit-transform: translateY(-11px) rotate(45deg) scaleX(0.175);
      -moz-transform: translateY(-11px) rotate(45deg) scaleX(0.175);
      -ms-transform: translateY(-11px) rotate(45deg) scaleX(0.175);
      -o-transform: translateY(-11px) rotate(45deg) scaleX(0.175);
      transform: translateY(-11px) rotate(45deg) scaleX(0.175); }
    body.fv_passed #mainMenu #btn i {
      background: #000; }
  #mainMenu .inner {
    position: relative;
    padding: 80px;
    padding-bottom: 70px;
    height: 100%;
    opacity: 0;
    transition-delay: 300ms;
    transition: 200ms ease-in-out;
    -webkit-transform: translateX(5%);
    -moz-transform: translateX(5%);
    -ms-transform: translateX(5%);
    -o-transform: translateX(5%);
    transform: translateX(5%); }
    body.menu_on #mainMenu .inner {
      pointer-events: auto;
      opacity: 1;
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%); }
  #mainMenu a.bnr-contact {
    height: 40px;
    width: 400px;
    position: absolute;
    bottom: 70px;
    left: 50%;
    background-image: linear-gradient(135deg, #000000 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, #000000 50%, #000000 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
    background-size: 8.49px 8.49px;
    padding: 5px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
    #mainMenu a.bnr-contact i {
      text-align: center;
      background: #fff;
      display: block;
      height: 100%;
      line-height: 30px; }
  #mainMenu #cntl {
    height: 30px;
    font-size: 0;
    position: relative;
    z-index: 1; }
    #mainMenu #cntl .nav-item {
      letter-spacing: .08em;
      display: inline-block;
      font-size: 14px;
      list-style-type: 30px;
      margin-right: 30px;
      line-height: 30px;
      cursor: pointer;
      transition: 300ms;
      border-bottom: 3px solid rgba(255, 255, 255, 0); }
      #mainMenu #cntl .nav-item.current {
        border-bottom: 3px solid white; }
      #mainMenu #cntl .nav-item:last-of-type {
        margin-right: 0; }

#desc-wrap {
  position: absolute;
  left: 80px;
  top: 170px;
  width: calc(100% - 160px);
  height: calc(100% - 170px - 160px); }
  #desc-wrap .desc {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    transition: 200ms;
    pointer-events: none; }
    body.menu_on #desc-wrap .desc.current {
      display: block;
      opacity: 1;
      pointer-events: auto; }
  #desc-wrap .hd {
    font-size: 14px;
    line-height: 1;
    position: relative;
    display: block; }
    #desc-wrap .hd:before {
      content: "";
      position: absolute;
      top: 50%;
      left: -10px;
      margin-top: -1px;
      -webkit-transform: translate(-100%, -50%);
      -moz-transform: translate(-100%, -50%);
      -ms-transform: translate(-100%, -50%);
      -o-transform: translate(-100%, -50%);
      transform: translate(-100%, -50%);
      width: 100px;
      height: 5px;
      background-image: url(../img/arrw-R@2x.png);
      background-repeat: no-repeat;
      -webkit-background-size: auto 5px;
      background-size: auto 5px;
      background-position: top right; }
  #desc-wrap dl {
    pointer-events: none;
    z-index: 1;
    position: relative;
    margin-bottom: 100px; }
    #desc-wrap dl dt {
      line-height: 1;
      font-size: 26px;
      font-weight: 600;
      margin: 30px 0;
      letter-spacing: .15em; }
    #desc-wrap dl dd {
      font-size: 16px;
      line-height: 2.25;
      letter-spacing: .1em;
      margin: 0;
      height: auto !important; }
  #desc-wrap .btn-detail {
    display: block;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.71);
    line-height: 30px;
    width: 150px;
    padding-left: 20px;
    font-size: 12px;
    top: 0;
    left: 0; }
    #desc-wrap .btn-detail:after {
      content: "";
      position: absolute;
      top: 13px;
      right: -20px;
      width: 40px;
      height: 5px;
      background-image: url(../img/arrw-R@2x.png);
      background-repeat: no-repeat;
      -webkit-background-size: auto 5px;
      background-size: auto 5px;
      background-position: top right; }

/* !!------------------------------------ */
/* !! サイドメニュー */
aside#menu {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 30px;
  transition: 200ms;
  width: 384px;
  background: #F8FAFA; }
  aside#menu > .inner {
    position: absolute;
    right: 0;
    top: 0;
    width: 384px;
    padding: 50px 30px;
    height: 100%; }
  aside#menu a.logo {
    display: block;
    margin: 0 auto;
    width: 250px; }
    aside#menu a.logo img {
      transition: 200ms; }
    aside#menu a.logo ._1 {
      opacity: 1; }
    aside#menu a.logo ._2 {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0; }

#weather-wrap {
  margin-top: 48px;
  background: #fff;
  padding: 30px;
  font-family: roboto-slab;
  line-height: 1; }
  #weather-wrap p {
    text-align: center !important; }
  #weather-wrap #w-date {
    font-size: 3.2rem;
    line-height: 1; }
  #weather-wrap #w-day {
    background: #000;
    width: 100% !important;
    line-height: 20px;
    color: #fff;
    font-family: roboto;
    font-size: 1.4rem;
    margin-top: 15px; }
  #weather-wrap #w-wrap {
    display: flex;
    margin: 10px 0; }
    #weather-wrap #w-wrap span {
      display: block;
      width: 50%;
      height: 50px;
      position: relative; }
      #weather-wrap #w-wrap span i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    #weather-wrap #w-wrap #w-temp {
      border-left: 1px solid #000; }
      #weather-wrap #w-wrap #w-temp i {
        font-size: 3.6rem;
        line-height: 1; }
        #weather-wrap #w-wrap #w-temp i:after {
          content: "℃";
          font-size: 2rem; }
  #weather-wrap #w-loc {
    font-size: 12px;
    line-height: 1; }

#w-icon i {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  width: 43px;
  height: 100%;
  position: relative;
  bottom: 1px; }

.Clear {
  background-image: url(../img/Clear.svg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

.Clouds {
  background-image: url(../img/Clouds.svg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

.Rain, .Drizzle, .Thunderstorm {
  background-image: url(../img/Rain.svg);
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-position: center center; }

#search-wrap {
  background: #fff;
  width: 100%;
  height: 40px;
  margin: 20px 0;
  padding-left: 40px;
  position: relative; }
  #search-wrap input {
    display: block;
    height: 40px;
    width: 100%;
    font-size: 18px; }
    #search-wrap input:focus {
      outline: none; }
  #search-wrap button {
    display: block;
    background-color: #fff;
    width: 40px;
    height: 40px;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    background: #fff; }
    #search-wrap button:before {
      font-size: 40px; }
    #search-wrap button:hover:before {
      background: #FFE100;
      color: #fff; }

#side-footer {
  text-align: center;
  width: 100% !important;
  position: absolute;
  bottom: 30px;
  left: 0; }
  #side-footer .btn-wrap {
    font-size: 0; }
    #side-footer .btn-wrap a {
      display: inline-block;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      background: #000;
      color: #fff;
      font-size: 4rem !important;
      line-height: 40px;
      margin: 0 2.5px; }
    #side-footer .btn-wrap .small {
      display: none; }
  #side-footer .copy {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 100;
    text-align: center;
    line-height: 1; }

body.side_small aside#menu {
  padding: 0px;
  width: 80px;
  background: #fff; }
  body.side_small aside#menu > .inner {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    padding: 0;
    height: 100%; }
  body.side_small aside#menu a.logo {
    display: block;
    margin: 0 auto;
    width: 80px;
    height: 80px; }
    body.side_small aside#menu a.logo ._1 {
      opacity: 0;
      pointer-events: none; }
    body.side_small aside#menu a.logo ._2 {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0; }

body.side_small #weather-wrap {
  margin-top: 0;
  padding: 0;
  position: absolute;
  bottom: 107px;
  left: 0;
  line-height: 1;
  background: none; }
  body.side_small #weather-wrap p {
    text-align: center !important;
    padding: 10px; }
  body.side_small #weather-wrap #w-date i {
    font-size: 40px;
    display: block;
    text-align: left;
    letter-spacing: .15em; }
    body.side_small #weather-wrap #w-date i.y {
      font-size: 2rem; }
  body.side_small #weather-wrap #w-day {
    background: #000;
    width: 100% !important;
    line-height: 20px;
    color: #fff;
    padding: 0;
    font-family: roboto;
    font-size: 1.4rem;
    margin-top: 0px; }
  body.side_small #weather-wrap #w-wrap {
    display: block;
    margin: 10px 0;
    text-align: center;
    padding: 0 25px; }
    body.side_small #weather-wrap #w-wrap span {
      display: block;
      width: 100%;
      height: 50px;
      position: relative; }
      body.side_small #weather-wrap #w-wrap span i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    body.side_small #weather-wrap #w-wrap #w-temp {
      border-top: 1px solid #000;
      border-left: none; }
      body.side_small #weather-wrap #w-wrap #w-temp i {
        font-size: 2.4rem;
        line-height: 1; }
        body.side_small #weather-wrap #w-wrap #w-temp i:after {
          content: "℃";
          font-size: 1.2rem; }
  body.side_small #weather-wrap #w-loc {
    font-size: 12px;
    line-height: 1.3;
    width: 100%;
    padding: 0;
    white-space: normal; }
    body.side_small #weather-wrap #w-loc i {
      display: block; }

body.side_small #search-wrap {
  display: none; }

body.side_small #side-footer {
  text-align: center;
  width: 100% !important;
  position: absolute;
  bottom: 0px;
  left: 0; }
  body.side_small #side-footer .btn-wrap {
    display: flex;
    font-size: 0;
    flex-wrap: wrap;
    background: #999999; }
    body.side_small #side-footer .btn-wrap a {
      display: block;
      border-radius: 0;
      height: 40px;
      width: 40px;
      background: #999999;
      margin: 0; }
    body.side_small #side-footer .btn-wrap .small {
      display: block; }
  body.side_small #side-footer .copy {
    display: none; }

/* !!------------------------------------ */
/* !! トップページ */
#mv {
  height: 100vh;
  padding-left: 384px;
  position: relative;
  z-index: 0; }
  #mv .mv-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    #mv .mv-img img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: 0 50%; }
    #mv .mv-img span {
      position: absolute;
      bottom: 50px;
      right: 50px;
      z-index: 1;
      width: 346px; }

ul.news {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between; }
  ul.news.second {
    display: none; }
  ul.news.opened {
    display: flex; }
  ul.news li {
    width: calc((100% - 40px)/3);
    background: #fff;
    margin-bottom: 20px;
    transition: 300ms;
    position: relative;
    opacity: 1; }
    ul.news li > a {
      position: absolute;
      display: block;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    ul.news li .thumb img {
      display: block;
      width: 100% !important;
      height: auto !important; }
    ul.news li .wrap {
      padding: 15px 20px; }
    ul.news li .data {
      overflow: hidden; }
      ul.news li .data .cate {
        background: #ccc;
        font-weight: bold;
        font-size: 12px;
        color: #fff;
        line-height: 18px;
        width: 75px;
        vertical-align: middle;
        border-radius: 9px;
        background: #FFE100;
        display: block;
        text-align: center;
        float: left; }
      ul.news li .data .date {
        line-height: 18px;
        float: right;
        font-size: 12px; }
    ul.news li .title {
      font-size: 14px;
      text-align: justify;
      line-height: 1.43;
      margin-top: 15px; }

.service-wrap.second {
  display: none; }

ul.service {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 25% 25% 25% 25%; }
  ul.service li {
    background: #eee;
    position: relative; }
    ul.service li a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-right: 1px solid #fff; }
    ul.service li img {
      width: 100% !important;
      height: auto !important;
      display: block; }
  ul.service li:first-of-type {
    grid-row: 1 / 3;
    grid-column: 1 / 3; }
    ul.service li:first-of-type a {
      border-width: 0 1px 0 0; }
  ul.service li:nth-of-type(2) {
    grid-row: 1;
    grid-column: 3; }
    ul.service li:nth-of-type(2) a {
      border-width: 0 1px 1px 0; }
  ul.service li:nth-of-type(3) {
    grid-row: 1;
    grid-column: 4; }
    ul.service li:nth-of-type(3) a {
      border-width: 0 0 1px 0; }
  ul.service li:nth-of-type(4) {
    grid-row: 2;
    grid-column: 3; }
    ul.service li:nth-of-type(4) a {
      border-width: 0 1px 0 0; }
  ul.service li:nth-of-type(5) {
    grid-row: 2;
    grid-column: 4; }
    ul.service li:nth-of-type(5) a {
      border-width: 0; }
  ul.service.rv li:first-of-type {
    grid-row: 1 / 2;
    grid-column: 1 / 2; }
    ul.service.rv li:first-of-type a {
      border-width: 0 1px 0 0; }
  ul.service.rv li:nth-of-type(2) {
    grid-row: 1;
    grid-column: 2; }
    ul.service.rv li:nth-of-type(2) a {
      border-width: 0 1px 1px 0; }
  ul.service.rv li:nth-of-type(3) {
    grid-row: 2;
    grid-column: 1; }
    ul.service.rv li:nth-of-type(3) a {
      border-width: 0 0 1px 0; }
  ul.service.rv li:nth-of-type(4) {
    grid-row: 2;
    grid-column: 2; }
    ul.service.rv li:nth-of-type(4) a {
      border-width: 0 1px 0 0; }
  ul.service.rv li:nth-of-type(5) {
    grid-row: 1/3;
    grid-column: 3/5; }
    ul.service.rv li:nth-of-type(5) a {
      border-width: 0 1px 0 0; }

.scroll-down {
  width: 15px;
  height: 100px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }
  .scroll-down img {
    width: 100% !important;
    height: auto !important; }
  .scroll-down:before {
    content: "";
    width: 24px;
    height: 40px;
    display: block;
    border: 1px solid #fff;
    border-radius: 13px;
    position: absolute;
    top: -50px;
    left: -5px; }
  .scroll-down:after {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #fff;
    display: block;
    opacity: 0.7;
    position: absolute;
    top: -43px;
    left: 4px;
    animation: scroll 1s ease-in-out infinite alternate; }

@keyframes scroll {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(3px); }
  50% {
    transform: translateY(0px); }
  100% {
    transform: translateY(0); } }

.btn {
  display: inline-block;
  width: 300px;
  height: 50px;
  font-size: 0;
  margin: 30px auto !important;
  background: #fff;
  color: #000;
  font-size: 16px;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  line-height: 50px;
  letter-spacing: .1em;
  text-align: center; }
  .btn:hover {
    background: rgba(255, 255, 255, 0.2); }
  .btn.inv polygon {
    animation: none !important; }
  .btn > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
    .btn > svg polygon {
      stroke: #000;
      stroke-width: #000;
      stroke-dashoffset: 2000;
      fill: none;
      stroke-dasharray: 2000;
      animation: st1 1.5s ease-in 0s forwards; }
  .btn.bg3 {
    background: -moz-linear-gradient(16.86% -130.07% -40deg, #e05cff 0%, #2efff2 100%);
    background: -webkit-linear-gradient(-40deg, #e05cff 0%, #2efff2 100%);
    background: -webkit-gradient(linear, 16.86% -130.07%, 83.14% 230.07%, color-stop(0, #e05cff), color-stop(1, #2efff2));
    background: -o-linear-gradient(-40deg, #e05cff 0%, #2efff2 100%);
    background: -ms-linear-gradient(-40deg, #e05cff 0%, #2efff2 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E05CFF', endColorstr='#2EFFF2' ,GradientType=0)";
    background: linear-gradient(130deg, #e05cff 0%, #2efff2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E05CFF',endColorstr='#2EFFF2' , GradientType=1);
    color: #fff; }

@keyframes st1 {
  0% {
    stroke-dashoffset: 2000; }
  100% {
    stroke-dashoffset: 0; } }

/* !!------------------------------------ */
/* !! 下層ページ */
#lead {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0; }
  #lead #desc-wrap {
    padding-left: 384px;
    opacity: 1;
    z-index: 4; }
    body.side_small #lead #desc-wrap {
      padding-left: 80px; }
    #lead #desc-wrap .desc {
      opacity: 1;
      position: relative;
      display: block;
      color: #fff !important;
      pointer-events: auto; }
      #lead #desc-wrap .desc.b {
        color: #000 !important; }
        #lead #desc-wrap .desc.b .hd:before {
          background-image: url(../img/arrw-R@2x.png);
          background-repeat: no-repeat;
          -webkit-background-size: auto 5px;
          background-size: auto 5px;
          background-position: top right; }
    #lead #desc-wrap .hd:before {
      background-image: url(../img/arrw-R-W@2x.png);
      background-repeat: no-repeat;
      -webkit-background-size: auto 5px;
      background-size: auto 5px;
      background-position: top right; }
    #lead #desc-wrap .btn-detail {
      border: 1px solid #fff; }
      #lead #desc-wrap .btn-detail:after {
        background-image: url(../img/arrw-R-W@2x.png);
        background-repeat: no-repeat;
        -webkit-background-size: auto 5px;
        background-size: auto 5px;
        background-position: top right; }

#content {
  padding: 0 7vw;
  padding-left: calc(	7vw + 80px);
  position: relative;
  background: #F8FAFA; }
  #content > .inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 7vw 2.1vw;
    position: relative; }
  #content hr {
    display: block;
    height: 1px;
    border: none;
    background: #000;
    max-width: 1200px;
    margin: 0 auto; }
  #content h2 {
    font-size: 1.8rem;
    letter-spacing: .15em;
    font-weight: normal; }
    #content h2 i {
      font-size: 0.67em;
      display: block; }
    #content h2:after {
      content: "";
      background: #000;
      transition: 600ms;
      display: block;
      height: 1px;
      width: 30px;
      margin: 50px 0; }
  #content p.hd {
    font-size: 2.6rem;
    line-height: 1;
    letter-spacing: .15em;
    margin-bottom: 30px; }
  #content p {
    font-size: 1.6rem;
    line-height: 2.25;
    letter-spacing: .1em; }
  #content .page-footer {
    padding: 100px 0; }
    #content .page-footer h2 {
      padding-right: 40px;
      overflow: hidden;
      position: relative; }
      #content .page-footer h2:after {
        content: "";
        position: absolute;
        left: 318px;
        top: 50%;
        width: calc( 100% - 618px);
        background: #000;
        height: 1px;
        margin: 0;
        transition: 1000ms cubic-bezier(0, 1, 0.59, 1);
        transform-origin: left top; }
    #content .page-footer .page {
      line-height: 1;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -60px;
      font-style: italic;
      font-size: 16px;
      letter-spacing: 0; }
    #content .page-footer .btn {
      position: absolute;
      margin: 0 !important;
      right: 0;
      top: 50%;
      border: 1px solid #000;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      opacity: 1;
      transition-delay: 1000ms; }
    #content .page-footer.inv h2:after {
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      -ms-transform: scaleX(0);
      -o-transform: scaleX(0);
      transform: scaleX(0); }
    #content .page-footer.inv .btn {
      opacity: 0; }

/* !!------------------------------------ */
/* !! ABOUT US */
body.aboutus .mv-img .span {
  width: 345px; }

body.aboutus .dec1 {
  position: absolute;
  bottom: 100px;
  left: 684px; }

body.aboutus .idx > div {
  width: calc(50% - 70px); }
  body.aboutus .idx > div strong {
    font-size: 16px; }

/* !!------------------------------------ */
/* !! PROJECT */
body.project .mv-img .span {
  width: 402px; }

body.project .dec1 {
  position: absolute;
  bottom: 100px;
  left: 684px; }

body.project .project-item {
  background: #fff;
  width: calc(50% - 1.75vw);
  position: relative;
  padding-bottom: 50px; }
  body.project .project-item .img img {
    width: 100% !important;
    height: auto !important; }
  body.project .project-item .txt {
    padding: 40px 50px; }
    body.project .project-item .txt p {
      font-size: 1.6rem;
      line-height: 1.75 !important; }
    body.project .project-item .txt p.name {
      line-height: 1 !important;
      font-size: 24px !important;
      margin-bottom: 30px; }
    body.project .project-item .txt p.lead {
      font-size: 24px !important;
      line-height: 1 !important;
      margin-bottom: 20px; }
    body.project .project-item .txt p.url {
      margin-top: 20px;
      line-height: 1;
      font-size: 1.4rem !important;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 15px 50px;
      padding-bottom: 40px; }

/* !!------------------------------------ */
/* !! BUSINESS */
body.business.side_small aside#menu {
  background: rgba(255, 255, 255, 0.7); }

body.business .mv-img .span {
  width: 470px; }

body.business .idx > div {
  width: calc(50% - 70px); }

body.business #content {
  padding: 0; }
  body.business #content > .row {
    padding: 0;
    position: relative;
    margin-top: -14.7vw; }
    body.business #content > .row:first-of-type {
      margin-top: 0; }
    body.business #content > .row:nth-of-type(2n) .box {
      right: auto;
      left: 50%;
      margin-right: 0;
      margin-left: -14vw; }
    body.business #content > .row.last .box {
      margin-top: 3.5vw; }
    body.business #content > .row .box {
      padding: 3.5vw;
      background: #fff;
      position: absolute;
      top: 50%;
      right: 50%;
      margin-right: -14vw;
      width: 700px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      body.business #content > .row .box h2:after {
        margin: 2.1vw 0; }
      body.business #content > .row .box p.hd {
        font-size: 26px;
        line-height: 1.62;
        padding-top: 0;
        margin-top: -0.31em;
        margin-bottom: -0.31em; }
      body.business #content > .row .box p {
        margin-top: -0.625em;
        margin-bottom: -0.625em;
        padding-top: 30px; }
      body.business #content > .row .box ul.ms {
        margin-top: 15px;
        padding-left: .5em;
        display: none; }
        body.business #content > .row .box ul.ms li {
          font-size: 1.4rem;
          letter-spacing: .1em;
          line-height: 2;
          padding-left: 1.4em;
          position: relative; }
          body.business #content > .row .box ul.ms li:before {
            font-style: normal;
            position: absolute;
            top: 0;
            left: 0; }
          body.business #content > .row .box ul.ms li:before {
            content: "・"; }
    body.business #content > .row > img {
      width: 100% !important;
      height: auto !important; }

body.business .page-footer {
  width: 100%;
  position: relative;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -ms-transform: translateX(40px);
  -o-transform: translateX(40px);
  transform: translateX(40px); }

.btn-toggle {
  background: #000;
  width: 200px;
  border-radius: 4px;
  height: 60px;
  font-size: 14px;
  position: relative;
  margin-top: 50px;
  cursor: pointer; }
  .btn-toggle i {
    color: #fff;
    display: block;
    width: 100%;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 20px;
    letter-spacing: .1em;
    font-family: 'Open Sans', sans-serif; }
    .btn-toggle i:after {
      display: block;
      position: absolute;
      left: 0;
      top: 10px;
      content: "";
      width: 8px;
      height: 8px;
      border: 1px solid #fff;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transform-origin: center center;
      right: 20px !important;
      left: auto;
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(45deg);
      -moz-transform: translateY(-50%) rotate(45deg);
      -ms-transform: translateY(-50%) rotate(45deg);
      -o-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg); }

/* !!------------------------------------ */
/* !! COMPANY */
body.company .mv-img span {
  max-width: 475px !important; }

body.company .dec1 {
  position: absolute;
  bottom: 100px;
  left: 684px; }

body.company .inner.idx > div {
  width: calc(50% - 70px); }
  body.company .inner.idx > div strong {
    font-size: 26px; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

ul.table {
  width: calc(50% - 50px);
  letter-spacing: .1em; }
  ul.table li {
    padding: 15px;
    background: #EEEEEF;
    padding-left: 150px;
    position: relative; }
    ul.table li:before {
      content: "";
      display: block;
      position: absolute;
      left: 120px;
      top: 15px;
      width: 1px;
      height: calc(100% - 30px);
      position: absolute;
      border-right: 1px dotted #666; }
    ul.table li i {
      position: absolute;
      font-size: 16px;
      left: 0;
      top: 0;
      height: 100%;
      padding: 15px;
      width: 120px;
      line-height: 1.88; }
    ul.table li span {
      display: block;
      position: relative;
      font-size: 16px;
      line-height: 1.88; }
    ul.table li:nth-of-type(2n) {
      background: none; }

.map-wrap {
  padding: 0 calc(120px + 2.1vw) !important;
  padding-bottom: 7vw !important; }
  .map-wrap h2 {
    position: absolute;
    top: 0;
    left: 2.1vw; }
  .map-wrap .map {
    width: 100%;
    height: 526px;
    position: relative; }
    .map-wrap .map iframe {
      width: 100% !important;
      height: 100% !important; }
    .map-wrap .map .data {
      position: absolute;
      width: 400px;
      background: #000;
      padding: 50px;
      right: -120px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      .map-wrap .map .data p {
        font-size: 16px;
        line-height: 1.88;
        position: .88em;
        color: #fff; }

/* !!------------------------------------ */
/* !! RECRUIT */
body.recruit .mv-img span {
  max-width: 443px !important;
  width: 100%; }

body.recruit .inner.idx > div {
  width: calc(50% - 70px); }
  body.recruit .inner.idx > div strong {
    font-size: 26px; }

body.recruit .select-btn-grp .btn-sl {
  line-height: 80px;
  color: #fff;
  background: #000;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  width: calc(50% - 25px);
  letter-spacing: .15em; }
  body.recruit .select-btn-grp .btn-sl.current {
    background: -moz-linear-gradient(16.86% -130.07% -40deg, #e05cff 0%, #2efff2 100%);
    background: -webkit-linear-gradient(-40deg, #e05cff 0%, #2efff2 100%);
    background: -webkit-gradient(linear, 16.86% -130.07%, 83.14% 230.07%, color-stop(0, #e05cff), color-stop(1, #2efff2));
    background: -o-linear-gradient(-40deg, #e05cff 0%, #2efff2 100%);
    background: -ms-linear-gradient(-40deg, #e05cff 0%, #2efff2 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E05CFF', endColorstr='#2EFFF2' ,GradientType=0)";
    background: linear-gradient(130deg, #e05cff 0%, #2efff2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E05CFF',endColorstr='#2EFFF2' , GradientType=1);
    color: #FFF; }

body.recruit .row2 {
  margin: 50px 0; }
  body.recruit .row2 .btn-sl {
    background: #fff;
    line-height: 80px;
    color: #000;
    width: calc(100% / 3) !important;
    margin: 0 !important;
    border-right: 1px dotted #ccc; }
    body.recruit .row2 .btn-sl:last-of-type {
      border-right: 0; }

body.recruit .btn.line {
  position: relative;
  display: block;
  margin: 0 auto !important;
  border: 1px solid #000;
  opacity: 1;
  transition-delay: 1000ms;
  cursor: pointer; }

body.recruit #requirement ul {
  overflow: hidden;
  position: relative; }
  body.recruit #requirement ul:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: #000;
    left: 120px;
    top: 0; }
  body.recruit #requirement ul li {
    font-size: 16px;
    margin: 50px 0;
    padding-left: 150px;
    position: relative;
    line-height: 1; }
    body.recruit #requirement ul li span {
      display: block;
      margin-bottom: 15px; }
      body.recruit #requirement ul li span:last-of-type {
        margin-bottom: 0; }
    body.recruit #requirement ul li i {
      position: absolute;
      left: 0; }

body.recruit #requirement ._2 {
  position: absolute;
  left: 483px;
  bottom: 0;
  bottom: 7vw; }

/* !!------------------------------------ */
/* !! フッター */
footer {
  display: block;
  background: #000;
  position: relative;
  padding: 50px 5%;
  padding-left: calc(5% + 80px);
  background: #1EFFB8;
  text-align: center; }
  footer .inner {
    position: relative;
    width: 100%;
    height: 100%; }
  footer #contact {
    text-align: center; }
    footer #contact p.hd {
      text-align: center;
      font-size: 14px;
      margin-bottom: 40px; }
      footer #contact p.hd strong {
        text-align: center;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 40px;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        display: block; }
    footer #contact .btn {
      padding: 0;
      margin: 0 !important;
      background: none !important; }
  footer .copy {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px; }

.pc {
  display: block; }

.sp {
  display: none !important; }

#responsive_flg {
  display: none; }

/* ------------------------------------------------------------*/
/* sp 〜767
------------------------------------------------------------*/
