@charset "UTF-8";
/* CSS Document */


$font01 : 'DIN','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','YuGothic', 'Yu Gothic','游ゴシック','メイリオ','Meiryo',sans-serif;
$pink:#d94667;

$px:0.07vw;

@import url("https://use.typekit.net/iqf0tiu.css");

* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
}

ul , ol {
	list-style: none;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure {
	margin: 0;
	padding: 0;
}

header,nav,main,footer,section,aside{
	display: block;
}

strong {
	font-weight: normal;
}

form {
	margin-bottom: 0;
}

img {
	vertical-align:bottom;
}

::selection {
	color: #fff;
	background: #00aebb;
}

::-moz-selection {
	color: #fff;
	//background: #00aebb;
}

input::selection,
textarea::selection {
	color: #221814;
	background: #dbdcdc;
}

input[type=text],
button{
	-webkit-appearance: none;
	appearance: none;
	border: none;
	 
}

.collection main{
	overflow: hidden;
}

.noLink{
	text-decoration: none !important;
	cursor: default;
}

p{
	line-height: 1.6;
	//@include gothic;
	font-size: 1.4rem;
	text-align: justify;	
}

.ar{
	text-align: right;
}

i{
	font-style: normal;
}

.fr{
	float: right;
}


/* !!------------------------------------ */
/* !! 共通css */

html{
	font-size: 62.5%;
}

body{
	margin: 0 auto;
	padding: 0;
	color: $text;
	min-width: 1280px;
	width: 100%;
	background: #F8FAFA;
	//overflow-x: hidden;
	@include gothic;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	//max-width: 1920px;
	
}

@media screen and (min-width: 1200px) {
	overflow-x: hidden;
}

main{
	background: #F8FAFA;
	width: 100% !important;
	//max-width: 1920px;
	@media screen and (min-width: 1921px) {
		//width: 1920px;
		//margin: 0 auto;
	}
	>section{
		position: relative;
		background: #fff;
		padding: 5%;
		z-index: 2;
		padding-left: calc(5% + 80px);
		&:nth-of-type(2n){
			background: #F8FAFA;
		}
		>h2{
			@include minchou;
			font-size: 12px;
			font-weight: normal;
			line-height: 1;
			position: relative;
			margin-bottom: 50px;
			&:before{
				content: "";
				height: 1.5px;
				width: 500px;
				display: block;
				background: #000;
				position: absolute;
				left: -510px;
				top: 6px;
			}
			strong{
				line-height: 1;
				font-family: "bebas-neue", sans-serif;
				display: block;
				font-size: 4.5rem;
				margin-top: 13px;
			}
		}
		
		//!! 切り替え
		.toggle{
			cursor: pointer;
			width: 106px;
			height: 30px;
			position: absolute;
			top: 84px;
			right: 50px;
			padding-right: 20px;
			text-align: center;
			border-left: 1px solid #000;
			&:before{
				content: "VIEW ALL";
				line-height: 30px;
			}
			i{
				transition: 100ms transform;
				width: 20px;
				height: 30px;
				position: absolute;
				right: 0;top: 0;
				@include bg-img(url(../img/viewAll_1.svg)); //$url,$size,$position,$repeat
			}
			&:active{
				i{
					@include transform(scale(1) !important);
				}
			}
			&:hover{
				i{
					@include transform(scale(1.2));
				}
			}
			&.toggled{
				&:before{
					content: "VIEW SMALL";
				}
				i{
					@include bg-img(url(../img/viewAll_2.svg)); //$url,$size,$position,$repeat
				}
			}
		}
	}
}

header#menu{
	z-index: 1;
	color: #FFF;
	position: absolute;
	top: 0;left: 0;
	width: 100%;
}

ul.lang-menu{
	position: absolute;
	font-size: 0;
	white-space: nowrap;
	position: absolute;
	right: 120px;
	top: 40px;
	li{
		font-size: 14px;
		line-height: 40px;
		display: inline-block;
		margin-left: 15px;
		
	}
}

$tr:300ms;

.idx{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

/* !!------------------------------------ */
/* !! メインメニュー */
canvas{
	display: block;
	position: fixed;
	z-index: 1;
	width:100%;
	height:100%;
	opacity: .5;
	transition: 500ms;
	top: 0;left: 0;
	position: fixed;
	pointer-events: none;
	mix-blend-mode: multiply;
	body.menu_on &{
		opacity: 1;
		z-index: 3;
		mix-blend-mode: normal;
		@include transform(translateX(0%));
	}

}

#mainMenu{
	position: fixed;
	display: block;
	height: 100vh;
	width: calc(100vw - 384px);
	min-width: 880px;
	top: 0;right: 0;
	z-index: 10;
	pointer-events: none;
	body.side_small &{
		width: calc(100vw - 80px);
		//pointer-events: auto;
	}
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		width:100%;
		height:100%;
		opacity: 0;
		transition:200ms;
		@include transform(translateX(30%));
	}
	
/*
	&.project:before{
		@include bg2;
	}
	
	body.menu_on &:before{
		opacity: 1;
		@include transform(translateX(0%));
	}
*/
	
	#btn{
		width: 40px;
		height: 24px;
		position: absolute;
		top: 50px;right: 50px;
		cursor: pointer;
		z-index: 3;
		pointer-events: auto;
		@include transform(rotate(-180deg));
		
		i{
			transition: 300ms $ease1;

			position: absolute;
			width: 100%;
			height: 2px;
			border-radius: 2px;
			background: #fff;
			transform-origin: left center;
			@include transform(translateY(0) rotate(0) scaleX(1));
		}
		._1{top: 0;left: 0;}
		._2{top: 50%;left: 0;margin-top: -1px;}
		._3{bottom: 0;left: 0;transition-delay: 50ms;}
		
		body.menu_on & {
			i{
				background: #000;
			}
			._1{@include transform(translateY(11px) rotate(-45deg) scaleX(0.175));}
			._3{
				
				@include transform(translateY(-11px) rotate(45deg) scaleX(0.175));
			}
		}
		
		body.fv_passed & {
			i{
				background: #000;
			}
		}
	}
	
	.inner{
		
		position: relative;
		//background: rgba(232, 189, 189, 0.42);
		padding: 80px;
		padding-bottom: 70px;
		height: 100%;
		opacity: 0;
		transition-delay: 300ms;
		transition: 200ms ease-in-out;
		@include transform(translateX(5%));
		body.menu_on &{
			pointer-events: auto;
			opacity: 1;
			@include transform(translateX(0%));
		}
	}
	
	a.bnr-contact{
		height: 40px;
		width: 400px;
		position: absolute;
		bottom: 70px;left: 50%;
		@include stripe1;
		padding: 5px;
		@include transform(translateX(-50%));
		i{
			text-align: center;
			background: #fff;
			display: block;
			height: 100%;
			line-height: 30px;
		}
	}
	
	//!! コントロール
	#cntl{
		height: 30px;
		font-size: 0;
		position: relative;
		z-index: 1;
		.nav-item{
			letter-spacing: .08em;
			display: inline-block;
			font-size: 14px;
			list-style-type: 30px;
			margin-right: 30px;
			line-height: 30px;
			cursor: pointer;
			transition: 300ms;
			border-bottom: 3px solid rgba(255, 255, 255, 0);
			&.current{
				//font-weight: bold;
				border-bottom: 3px solid rgba(255, 255, 255, 1);
			}
			&:last-of-type{
				margin-right: 0;
			}
		}
	}
	
	
}


//!! コンテンツ
#desc-wrap{
	
	position: absolute;
	left: 80px;top: 170px;
	width: calc(100% - 160px);
	height: calc(100% - 170px - 160px);
	.desc{
		width: 100%;
		position: absolute;
		top: 0;left: 0;
		height: 100%;
		opacity: 0;
		transition: 200ms;
		pointer-events: none;
		//display: none;
		body.menu_on &.current{
			display: block;
			opacity: 1;
			pointer-events: auto;
		}
	}
	

	.hd{
		font-size: 14px;
		line-height: 1;
		position: relative;
		display: block;
		&:before{
			content: "";
			position: absolute;
			top: 50%;left:-10px;
			margin-top: -1px;
			
			@include transform(translate(-100%,-50%));
			width: 100px;height: 5px;
			@include bg-img(url(../img/arrw-R@2x.png),auto 5px,top right); //$url,$size,$position,$repeat
		}			
	}
	
	dl{
		pointer-events: none;
		z-index: 1;
		position: relative;
		margin-bottom: 100px;
		dt{
			line-height: 1;
			font-size: 26px;
			font-weight: 600;
			margin: 30px 0;
			letter-spacing: .15em;
		}
		dd{
			font-size: 16px;
			line-height: 2.25;
			letter-spacing: .1em;
			margin: 0;
			height: auto !important;
		}
	}
	
	.btn-detail{
		display: block;
		position: relative;
		border: 1px solid rgba(0, 0, 0, 0.71);
		line-height: 30px;
		width: 150px;
		padding-left: 20px;
		font-size: 12px;
		top: 0;left: 0;
		&:after{
			content: "";
			position: absolute;
			top: 13px;right:-20px;
			width: 40px;height: 5px;
			@include bg-img(url(../img/arrw-R@2x.png),auto 5px,top right); //$url,$size,$position,$repeat
		}
	}
	
}//#desc-wrap





/* !!------------------------------------ */
/* !! サイドメニュー */
aside#menu{
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;z-index: 10;
	padding: 30px;
	transition: 200ms;
	width: 384px;
	//background: #eee;
	background: #F8FAFA;
	>.inner{
		position: absolute;
		right: 0;top: 0;
		width: 384px;
		padding: 50px 30px;
		height: 100%;
	}
	a.logo{
		display: block;
		margin: 0 auto;
		width: 250px;
		img{
			transition: 200ms;
		}
		._1{
			opacity: 1;
		}
		._2{
			opacity: 0;
			position: absolute;
			top: 0;left: 0;
		}
	}

}

//!! 天気情報
#weather-wrap{
	margin-top: 48px;
	background: #fff;
	padding: 30px;
	font-family: roboto-slab;
	line-height: 1;
	p{
		text-align: center !important;
	}
	#w-date{
		font-size: 3.2rem;
		line-height: 1;
	}
	#w-day{
		background: #000;
		width: 100% !important;
		line-height: 20px;
		color: #fff;
		font-family: roboto;
		font-size: 1.4rem;
		margin-top: 15px;
	}
	#w-wrap{
		display: flex;
		margin: 10px 0;
		span{
			display: block;
			width: 50%;
			height: 50px;
			position: relative;
			i{
				position: absolute;
				top: 50%;left: 50%;
				@include transform(translate(-50%,-50%));
			}
		}
		#w-temp{
			border-left: 1px solid #000;
			i{
				font-size: 3.6rem;
				line-height: 1;
				&:after{
					content: "℃";
					font-size: 2rem;
				}
			}
		}
	}
	#w-loc{
		font-size: 12px;
		line-height: 1;
	}
} 


//!! - アイコン
#w-icon{
	i{
		display: inline-block;
		vertical-align: top;
		line-height:1;
		width: 43px;height: 100%;
		position: relative;
		bottom: 1px;
		//@include bg-img(url(../img/Clear.svg)); //$url,$size,$position,$repeat
	}
}
.Clear{
	@include bg-img(url(../img/Clear.svg)); //$url,$size,$position,$repeat
}
.Clouds {
	@include bg-img(url(../img/Clouds.svg));
}
.Rain,.Drizzle,.Thunderstorm{
	@include bg-img(url(../img/Rain.svg)); //$url,$size,$position,$repeat
}



//!! 検索
#search-wrap{
	background: #fff;
	width: 100%;
	height: 40px;
	margin: 20px 0;
	padding-left: 40px;
	position: relative;
	input{
		display: block;
		height: 40px;
		width: 100%;
		font-size: 18px;
		&:focus{
			outline: none;
		}
	}
	button{
		display: block;
		background-color: #fff;
		width: 40px;
		height: 40px;
		padding: 0;
		position: absolute;
		top: 0;left: 0;
		cursor: pointer;
		background: #fff;
		&:before{
			font-size: 40px;
		}
		&:hover:before{
			background:  $yellow;
			color: #fff;
		}
	}
	
}

//!! サイドフッター
#side-footer{
	text-align: center;
	width: 100% !important;
	position: absolute;
	bottom: 30px;left: 0;
	.btn-wrap{
		font-size: 0;
		a{
			display: inline-block;
			border-radius: 50%;
			height: 40px;width: 40px;
			background: #000;
			color: #fff;
			font-size: 4rem !important;
			line-height: 40px;
			margin: 0 2.5px;
		}
		.small{
			display: none;
		}
	}
	
	.copy{
		margin-top: 15px;
		font-size: 13px;
		font-weight: 100;
		text-align: center;
		line-height: 1;
	}
}



//!! - 小サイズ
body.side_small{
	aside#menu{
		padding: 0px;		
		width: 80px;
		background: #fff;
		>.inner{
			position: absolute;
			right: 0;top: 0;
			width: 100%;
			padding: 0;
			height: 100%;
		}
		a.logo{
			display: block;
			margin: 0 auto;
			width: 80px ;
			height: 80px;
			._1{
				opacity: 0;
				pointer-events: none;
			}
			._2{
				opacity: 1;
				position: absolute;
				top: 0;left: 0;
			}
		}
	
	}
	
	#weather-wrap{
		margin-top: 0;
		padding: 0;
		position: absolute;
		bottom: 107px;left: 0;
		line-height: 1;
		background: none;
		p{
			text-align: center !important;
			padding: 10px;
		}
		#w-date{
			i{
				font-size: 40px;
				display: block;
				text-align: left;
				letter-spacing: .15em;
				&.y{
					font-size: 2rem;
				}
			}
		}
		#w-day{
			background: #000;
			width: 100% !important;
			line-height: 20px;
			color: #fff;
			padding: 0;
			font-family: roboto;
			font-size: 1.4rem;
			margin-top: 0px;
		}
		
		#w-wrap{
			display: block;
			margin: 10px 0;
			text-align: center;
			padding:0 25px;
			span{
				display: block;
				width: 100%;
				height: 50px;
				position: relative;
				i{
					position: absolute;
					top: 50%;left: 50%;
					@include transform(translate(-50%,-50%));
				}
			}
			#w-temp{
				border-top: 1px solid #000;
				border-left: none;
				i{
					font-size: 2.4rem;
					line-height: 1;
					&:after{
						content: "℃";
						font-size: 1.2rem;
					}
				}
			}
		}
		#w-loc{
			font-size: 12px;
			line-height: 1.3;
			width: 100%;
			padding: 0;
			white-space: normal;
			i{
				display: block;
			}
		}
	} 
	
	#search-wrap{
		display: none;
	}
	
	#side-footer{
		text-align: center;
		width: 100% !important;
		position: absolute;
		bottom: 0px;left: 0;
		.btn-wrap{
			display: flex;
			font-size: 0;
			flex-wrap: wrap;
			background: #999999;
			a{
				display:block;
				border-radius:0;
				height: 40px;width: 40px;
				background: #999999;
				margin: 0;
			}
			.small{
				display: block;
			}
		}
		
		.copy{
			display: none;
		}
	}
	
	
}

/* !!------------------------------------ */
/* !! トップページ */


//!! MV
#mv{
	height: 100vh;
	padding-left: 384px;
	position: relative;
	z-index: 0;
	.mv-img{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 0;
		@include transform(translateY(-50%));
		img{
			width: 100% !important;
			height: 100%!important;
			object-fit: cover;
			object-position: 0 50%;
		}
		span{
			position: absolute;
			bottom: 50px;
			right: 50px;
			z-index: 1;
			width: 346px;
		}
	}
	
	
}



//!! - NEWS

ul.news{
	display: flex;
	flex-wrap: wrap;
	//transition: 200ms;
	width: 100%;
	justify-content: space-between;
	&.second{
		display: none;
	}
	&.opened{
		display: flex;
	}
	li{
		width: calc((100% - 40px)/3);
		background: #fff;
		margin-bottom: 20px;
		transition: 300ms;
		position: relative;
		opacity: 1;
		>a{
			@include a-cover;
		}
		.thumb{
			img{
				display: block;
				width: 100% !important;
				height: auto !important;
			}
		}
		.wrap{padding: 15px 20px;}
		.data{
			overflow: hidden;
			.cate{
				background: #ccc;
				font-weight: bold;
				font-size: 12px;
				color: #fff;
				line-height: 18px;
				width: 75px;
				vertical-align: middle;
				border-radius: 9px;
				background: $yellow;
				display: block;
				text-align: center;
				float: left;
			}
			.date{
				line-height: 18px;
				float: right;
				font-size: 12px;
			}
		}
		.title{
			font-size: 14px;
			text-align: justify;
			line-height: 1.43;
			margin-top: 15px;
		}
		
		
	}
}

//!! service
.service-wrap.second{
	display: none;
}
ul.service{
	display: grid;
	grid-template-columns:25% 25% 25% 25%;
	grid-template-rows:25% 25% 25% 25%;
	li{
		background: #eee;
		position: relative;
		a{
			position: absolute;
			top: 0;left: 0;
			width:100%;
			height:100%;
			z-index: 1;
			border-right: 1px solid #fff;
		}
		img{
			width: 100% !important;
			height: auto !important;
			display: block;
		}
	}
	li:first-of-type{
		grid-row: 1 / 3;
		grid-column: 1 / 3;
		//background: #000;
		a{border-width:0 1px 0 0;}
	}
	li:nth-of-type(2){
		grid-row: 1 ;
		grid-column: 3 ;
		a{border-width:0 1px 1px 0;}
	}
	li:nth-of-type(3){
		grid-row: 1 ;
		grid-column: 4 ;
		a{border-width:0 0 1px 0;}
	}
	li:nth-of-type(4){
		grid-row: 2 ;
		grid-column: 3 ;
		a{border-width:0 1px 0 0;}
	}
	li:nth-of-type(5){
		grid-row: 2 ;
		grid-column: 4 ;
		a{border-width:0 ;}
	}
	&.rv{
		li:first-of-type{
			grid-row: 1 / 2;
			grid-column: 1 / 2;
			//background: #000;
			a{border-width:0 1px 0 0;}
		}
		li:nth-of-type(2){
			grid-row: 1 ;
			grid-column: 2 ;
			a{border-width:0 1px 1px 0;}
		}
		li:nth-of-type(3){
			grid-row: 2 ;
			grid-column: 1 ;
			a{border-width:0 0 1px 0;}
		}
		li:nth-of-type(4){
			grid-row: 2 ;
			grid-column: 2 ;
			a{border-width:0 1px 0 0;}
		}
		li:nth-of-type(5){
			grid-row: 1/3 ;
			grid-column: 3/5 ;
			a{border-width:0 1px 0 0;}
		}
	}
}





.scroll-down{
	width: 15px;
	height: 100px;
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	@include transform(translate(-50%,50%));
	img{
		width: 100% !important;
		height: auto !important;
	}
	&:before{
		content: "";
		width: 24px;
		height: 40px;
		display: block;
		border: 1px solid #fff;
		border-radius: 13px;
		position: absolute;
		top: -50px;
		left: -5px;
	}
	&:after{
		content: "";
		width: 6px;
		height: 6px;
		border-radius: 6px;
		background: #fff;
		display: block;
		opacity: 0.7;
		position: absolute;
		top: -43px;
		left: 4px;
		animation: scroll 1s ease-in-out infinite alternate;
	}
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0);
  }
}





//!! - btn


.btn{
	display: inline-block;
	width: 300px;
	height: 50px;
	font-size: 0;
	//border: 1px solid  #fff;
	margin: 30px auto !important;
	background: #fff;
	color: #000;
	font-size: 16px;
	position: relative;
	font-family: 'Open Sans', sans-serif;
	line-height: 50px;
	letter-spacing: .1em;
	text-align: center;
	&:hover{
		background: rgba(255, 255, 255, 0.2);
	}
	&.inv{
		
		polygon{
			animation: none !important;
		}
		
	}
	>svg {
		display: block;
		position: absolute;
		top: 0;left: 0;
		width: 100% !important;
		height: 100% !important;
		polygon{
			stroke:#000;
			stroke-width:#000;
			stroke-dashoffset: 2000;
			fill:none;
			stroke-dasharray: 2000;
			animation: st1 1.5s ease-in 0s forwards;
			
		}
	}
	&.bg3{
		@include bg3;
		color: #fff;
	}
}


@keyframes st1 {
  0% {
    stroke-dashoffset: 2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/* !!------------------------------------ */
/* !! 下層ページ */

#lead{
	position: absolute;
	top: 0;left: 0;
	width: 100%;
	padding: 0;
	#desc-wrap{
		padding-left: 384px;
		opacity: 1;
		z-index: 4;
		body.side_small & {
			padding-left: 80px;
		}
		.desc{
			opacity: 1;
			position: relative;
			display: block;
			color: #fff !important;
			pointer-events: auto;
			&.b{
				color: #000 !important;
				.hd{
					&:before{
						@include bg-img(url(../img/arrw-R@2x.png),auto 5px,top right); //$url,$size,$position,$repeat
					}			
				}
			}
		}
		
		.hd{
			&:before{
				@include bg-img(url(../img/arrw-R-W@2x.png),auto 5px,top right); //$url,$size,$position,$repeat
			}			
		}
		
		
		.btn-detail{
			border: 1px solid #fff;
			&:after{
				@include bg-img(url(../img/arrw-R-W@2x.png),auto 5px,top right); //$url,$size,$position,$repeat
			}
		}

	}
}


#content{

	padding:0 100*$px;
	padding-left: calc(	7vw + 80px);
	position: relative;
	background: #F8FAFA;
	
	>.inner{
		max-width: 1200px;
		margin: 0 auto;
		padding: 100*$px 30*$px;
		position: relative;
	}
	
	hr{
		display: block;
		height: 1px;
		border: none;
		background: #000;
		max-width: 1200px;
		margin: 0 auto;
	}
	h2{
		font-size: 1.8rem;
		letter-spacing: .15em;
		font-weight: normal;
		i{
			font-size: 0.67em;
			display: block;
		}
		&:after{
			content: "";
			background: #000;
			transition: 600ms;
			display: block;height: 1px;width: 30px;
			margin: 50px 0;
		}
	}
	p.hd{
		font-size: 2.6rem;
		line-height: 1;
		letter-spacing: .15em;
		margin-bottom: 30px;
	}
	p{
		font-size: 1.6rem;
		line-height: 2.25;
		letter-spacing: .1em;
	}
	
	.page-footer{
		padding: 100px 0;
		h2{
			padding-right: 40px;
			overflow: hidden;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				left: 318px;top: 50%;
				width:calc( 100% - 618px );
				background: #000;
				height: 1px;
				margin: 0;
				transition: 1000ms cubic-bezier(0, 1, 0.59, 1);
				transform-origin: left top;
			}
		}
		.page{
			line-height: 1;
			position: absolute;
			right: 0;top: 50%;
			margin-top: -60px;
			font-style: italic;
			font-size: 16px;
			letter-spacing: 0;
		}
		.btn{
			position: absolute;
			margin: 0 !important;
			right: 0;top: 50%;
			border: 1px solid #000;
			@include transform(translateY(-50%));
			opacity: 1;
			transition-delay: 1000ms;
		}
		
		&.inv{
			h2:after{
				@include transform(scaleX(0));
			}
			.btn{
				opacity: 0;
			}
		}
		
	}

	
}


/* !!------------------------------------ */
/* !! ABOUT US */

body.aboutus{
	
	.mv-img{
		.span{
			width: 345px;
		}
	}
	
	.dec1{
		position: absolute;
		bottom: 100px;left: 684px;
	}
	
	.idx > div{
		strong{
			font-size: 16px;
		}
		width: calc(50% - 70px);
	}
}


/* !!------------------------------------ */
/* !! PROJECT */

body.project{
	.mv-img{
		.span{
			width: 402px;
		}
	}
	
	.dec1{
		position: absolute;
		bottom: 100px;left: 684px;
	}
	
	.project-item{
		background: #fff;
		width: calc(50% - #{25*$px});
		position: relative;
		padding-bottom: 50px;
		.img{
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.txt{
			padding:40px 50px;
			p{
				font-size: 1.6rem;
				line-height: 1.75 !important;
			}
			p.name{
				line-height: 1 !important;
				font-size: 24px !important;
				margin-bottom: 30px;
			}
			p.lead{
				font-size: 24px !important;
				line-height: 1 !important;
				margin-bottom: 20px;
			}
			p.url{
				margin-top: 20px;
				line-height: 1;
				font-size: 1.4rem !important;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding:15px 50px;
				padding-bottom: 40px;
			}
		}
	}
}


/* !!------------------------------------ */
/* !! BUSINESS */

body.business{
	
	&.side_small{
		aside#menu{
			background: rgba(255, 255, 255, 0.7);
		}
	}
	
	.mv-img{
		.span{
			width: 470px;
		}
	}
	
	.idx > div{
		width: calc(50% - 70px);
	}
	
	#content{
		padding: 0;
		>.row{
			padding: 0;
			position: relative;
			margin-top: -210*$px;
			&:first-of-type{
				margin-top: 0;
			}
			&:nth-of-type(2n){
				.box{
					right: auto;
					left: 50%;
					margin-right: 0;
					margin-left: -200*$px;
				}
			}
			&.last{
				.box{
					margin-top: 50*$px;
				}
			}
			.box{
				padding: 50*$px;
				background: #fff;
				position: absolute;
				top: 50%;
				right: 50%;
				margin-right: -200*$px;
				width: 700px;
				@include transform(translateY(-50%));
				h2{
					&:after{
						margin: 30*$px 0;
					}
				}
				p.hd{
					font-size: 26px;
					line-height: 1.62;
					padding-top: 0;
					@include pfix(.62em);
				}
				p{
					@include pfix(1.25em);
					padding-top: 30px;
				}
				
				ul.ms{
					margin-top: 15px;
					padding-left: .5em;
					display: none;
					li{
						font-size: 1.4rem;
						letter-spacing: .1em;
						line-height: 2;
						@include note();
						&:before{
							content: "・"
						}
					}
				}
				
			}
			>img{
				width: 100% !important;
				height: auto !important;
			}
		}
		
		
	}//#content
	
	.page-footer{
		width: 100%;
		position: relative;
		@include transform(translateX(40px));
		//padding-left: calc( 7vw + 80px) !important;
	}
	
}

.btn-toggle{
	background: #000;
	width: 200px;
	border-radius: 4px;
	height: 60px;
	font-size: 14px;
	position: relative;
	margin-top: 50px;
	cursor: pointer;
	i{
		color: #fff;
		display: block;
		width: 100%;
		line-height: 1;
		position: absolute;
		top: 50%;
		left: 0;
		@include transform(translateY(-50%));
		padding: 0 20px;
		letter-spacing: .1em;
		@include OPS;
		&:after{
			@include anchor(#fff);
			right: 20px !important;
			left: auto;
			top: 50%;
			@include transform(translateY(-50%) rotate(45deg));
		}
	}
}



/* !!------------------------------------ */
/* !! COMPANY */

body.company{
	
	.mv-img{
		span{
			max-width: 475px !important;
		}
	}
	
	.dec1{
		position: absolute;
		bottom: 100px;left: 684px;
	}
	
	.inner.idx > div{
		strong{
			font-size: 26px;
		}
		width: calc(50% - 70px);
	}
	
	
	
	
}

.pt0{
	padding-top: 0 !important;
}

.pr0{
	padding-right: 0 !important;
}

ul.table{
	width: calc(50% - 50px);
	letter-spacing: .1em;
	li{
		padding: 15px;
		background: #EEEEEF;
		padding-left: 150px;
		position: relative;
		&:before{
			content: "";
			display: block;
			position: absolute;
			left: 120px;
			top: 15px;
			width: 1px;
			height: calc(100% - 30px);
			position: absolute;
			border-right: 1px dotted #666;
		}
		i{
			position: absolute;
			font-size: 16px;
			left: 0;
			top: 0;
			height: 100%;
			padding: 15px;
			width: 120px;
			line-height: 1.88;
		}
		span{
			display: block;
			position: relative;
			font-size: 16px;
			line-height: 1.88;
			
		}
		&:nth-of-type(2n){
			background:none;
		}
	}
}


.map-wrap{
	padding: 0 calc(120px + 2.1vw ) !important;
	padding-bottom: 100*$px !important;
	h2{
		position: absolute;
		top: 0;
		left: 2.1vw;
	}
	.map{
		width: 100%;
		height: 526px;
		position: relative;
		iframe{
			width: 100% !important;
			height: 100% !important;
		}
		.data{
			position: absolute;
			width: 400px;
			background: #000;
			padding: 50px;
			right: -120px;
			top: 50%;@include transform(translateY(-50%));
			p{
				font-size: 16px;
				line-height: 1.88;
				position: .88em;
				color: #fff;
			}
		}
	}
}



/* !!------------------------------------ */
/* !! RECRUIT */

body.recruit{
	
	.mv-img{
		span{
			max-width: 443px !important;
			width: 100%;
		}
	}
	
	
	.inner.idx > div{
		strong{
			font-size: 26px;
		}
		width: calc(50% - 70px);
	}
	
	.select-btn-grp .btn-sl{
		line-height: 80px;
		color: #fff;
		background: #000;
		text-align: center;
		font-size: 18px;
		cursor: pointer;
		width: calc(50% - 25px);
		letter-spacing: .15em;
		&.current{
			@include bg3;
			color: #FFF;
		}
		
	}
	
	.row2{
		margin: 50px 0;
		.btn-sl{
			background: #fff;
			line-height: 80px;
			color: #000;
			width: calc(100% / 3) !important;
			margin: 0 !important;
			border-right: 1px dotted #ccc;
			&:last-of-type{
				border-right: 0;
			}
		}
	}
	
	.btn.line{
		position: relative;
		display: block;
		margin: 0 auto !important;
		border: 1px solid #000;
		opacity: 1;
		transition-delay: 1000ms;
		cursor: pointer;
	}
	
	#requirement {
		ul{
			overflow: hidden;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				height: 100%;
				width: 1px;
				background: #000;
				left: 120px;
				top: 0;
			}
			li{
				font-size: 16px;
				margin: 50px 0;
				padding-left: 150px;
				position: relative;
				line-height: 1;
				span{
					display: block;
					margin-bottom: 15px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
				i{
					position: absolute;
					left: 0;
				}
			}
		}
		._2{
			position: absolute;
			left: 483px;
			bottom: 0;
			bottom: 100*$px;
		}
	}
	
}


/* !!------------------------------------ */
/* !! フッター */
footer{
	display: block;
	background: #000;
	position: relative;
	padding:50px 5%;
	padding-left: calc(5% + 80px);
	background: #1EFFB8;
	text-align: center;
	.inner{
		position: relative;
		width:100%;
		height:100%;
	}
	#contact{
		text-align: center;
		p.hd{
			text-align: center;
			font-size: 14px;
			margin-bottom: 40px;
			strong{
				text-align: center;
				font-size: 24px;
				line-height: 1;
				margin-bottom: 40px;
				font-weight: bold;
				font-family: 'Open Sans', sans-serif;
				display: block;
			}
		}
		.btn{
			padding: 0;
			margin: 0 !important;
			background: none !important;

		}
	}
	
	.copy{
		position: absolute;
		bottom: 0;right: 0;
		font-size: 12px;
	}

}
