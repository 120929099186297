$base:#947848;
$base-lite:#EFEBE4;
$blue:#00B9EE;
$pink:#d94667;
$text:#231815;
$gray-lite:#EEEEEF;
$line:#BDBAB9;
$gray:#333333;
$red:#9D1014;
$yellow:#FFE100;
$p:4.7vw;

$ease1: cubic-bezier(0, 0.96, 0.7, 1.2);

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

@mixin OPS{
	font-family: 'Open Sans', sans-serif;

}

@mixin fitW($w:$m){
	margin-left: -$w !important;
	width: 100vw !important;
}


@mixin pfix($gap){
	$em:$gap/2;
	margin-top: -$em;
	margin-bottom: -$em;
}

@mixin note($pl:1.4em){
	padding-left: $pl;
	position: relative;
	&:before{
		font-style: normal;
		position: absolute;
		top: 0;left: 0;
	}
}


@mixin bg1{
	background : -webkit-linear-gradient(-38.66deg, rgba(240, 235, 76, 1) 0%, rgba(255, 0, 184, 1) 100%);
	background : -webkit-gradient(linear,22.92% 22.29% ,98.26% 99.36% ,color-stop(0,rgba(240, 235, 76, 1) ),color-stop(1,rgba(255, 0, 184, 1) ));
	background : -o-linear-gradient(-38.66deg, rgba(240, 235, 76, 1) 0%, rgba(255, 0, 184, 1) 100%);
	background : -ms-linear-gradient(-38.66deg, rgba(240, 235, 76, 1) 0%, rgba(255, 0, 184, 1) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#F0EB4C', endColorstr='#FF00B8' ,GradientType=0)";
	background : linear-gradient(128.66deg, rgba(240, 235, 76, 1) 0%, rgba(255, 0, 184, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F0EB4C',endColorstr='#FF00B8' , GradientType=1);
}

@mixin bg2
{
  background : -moz-linear-gradient(22.92% 22.29% -38.66deg,rgba(255, 69, 156, 1) 0%,rgba(0, 156, 255, 1) 100%);
  background : -webkit-linear-gradient(-38.66deg, rgba(255, 69, 156, 1) 0%, rgba(0, 156, 255, 1) 100%);
  background : -webkit-gradient(linear,22.92% 22.29% ,98.26% 99.36% ,color-stop(0,rgba(255, 69, 156, 1) ),color-stop(1,rgba(0, 156, 255, 1) ));
  background : -o-linear-gradient(-38.66deg, rgba(255, 69, 156, 1) 0%, rgba(0, 156, 255, 1) 100%);
  background : -ms-linear-gradient(-38.66deg, rgba(255, 69, 156, 1) 0%, rgba(0, 156, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF459C', endColorstr='#009CFF' ,GradientType=0)";
  background : linear-gradient(128.66deg, rgba(255, 69, 156, 1) 0%, rgba(0, 156, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF459C',endColorstr='#009CFF' , GradientType=1);
}

@mixin bg3
{
  background : -moz-linear-gradient(16.86% -130.07% -40deg,rgba(224, 92, 255, 1) 0%,rgba(46, 255, 242, 1) 100%);
  background : -webkit-linear-gradient(-40deg, rgba(224, 92, 255, 1) 0%, rgba(46, 255, 242, 1) 100%);
  background : -webkit-gradient(linear,16.86% -130.07% ,83.14% 230.07% ,color-stop(0,rgba(224, 92, 255, 1) ),color-stop(1,rgba(46, 255, 242, 1) ));
  background : -o-linear-gradient(-40deg, rgba(224, 92, 255, 1) 0%, rgba(46, 255, 242, 1) 100%);
  background : -ms-linear-gradient(-40deg, rgba(224, 92, 255, 1) 0%, rgba(46, 255, 242, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#E05CFF', endColorstr='#2EFFF2' ,GradientType=0)";
  background : linear-gradient(130deg, rgba(224, 92, 255, 1) 0%, rgba(46, 255, 242, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E05CFF',endColorstr='#2EFFF2' , GradientType=1);
}


@mixin stripe1{
	background-image: linear-gradient(135deg, #000000 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, #000000 50%, #000000 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
	background-size: 8.49px 8.49px;
}

@import url(../fonts/icomoon/style.css);

@mixin minchou{
	//font-family: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
}

@mixin gothic{
	font-family:"Hiragino Sans","Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important;
}

@mixin bg-img($url,$size:100% 100%,$position:center center,$repeat:no-repeat){
	background-image: $url;
	background-repeat: $repeat;
	-webkit-background-size:$size ;
	background-size:$size ;
	background-position:$position;
}



@mixin transition($arg){
	-webkit-transition:$arg;
	-moz-transition:$arg;
	-ms-transition:$arg;
	-o-transition:$arg;
	//filter:$arg;
}


@mixin filter($arg){
	-webkit-filter:$arg;
	-moz-filter:$arg;
	-ms-filter:$arg;
	-o-filter:$arg;
	filter:$arg;
}

@mixin transform($arg){
	-webkit-transform:$arg;
	-moz-transform:$arg;
	-ms-transform:$arg;
	-o-transform:$arg;
	transform:$arg;
}

@mixin brend($color){
	background-blend-mode: exclusion;
	background-color: $color ;
}

@mixin webfont($fw:600){
		//font-family: 'Ropa Sans', sans-serif;
		font-family: 'Roboto Slab', serif !important;
		font-weight: $fw;
	}

@mixin header-icon-shadow(){
	-webkit-filter:drop-shadow(rgba(0,0,0,0.8) 1px 1px 2px);
}

@mixin a-cover{
	position: absolute;
	display: block;
	z-index: 1;
	top: 0;
	left: 0;
	width:100%;
	height:100%;
}

@mixin centering{
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
}

@mixin side-kakko{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: 1px solid #666;
		border-right-width: 0;
	}
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: 1px solid #666;
		border-right-width: 0;
		height: 100%;
		width: 15px;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;right: 0;
		border: 1px solid #666;
		border-left-width: 0;
		height: 100%;
		width: 15px;
	}
}

@mixin bar($color){
	content: "";
	width: 50px;
	height: 5px;
	background:$color ;
	display:block;
	margin: 0 auto;
}


@mixin anchor($color:#000,$size:8px){
	display: block;
	position: absolute;
	left: 0;top: 10px;
	content: "";
	width: $size;height: $size;
	border: 1px solid  $color;
	border-width: 0 1px 1px 0;
	@include transform(rotate(-45deg));
	transform-origin: center center;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  } 
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

@include keyframes(hover){
    
    30%{
	    //transform-origin: bottom;
	    @include transform(translate3d(0,-12px,20px) scale(1) /* rotateY(-3deg) */ rotateX(-5deg));
    }
    
    100%{
	    //transform-origin: bottom;
	    @include transform(translate3d(0,-10px,15px) scale(1) /* rotateY(-3deg) */ rotateX(-2deg));
    }
}

@include keyframes(out){
    
    
    0%{
	    //transform-origin: bottom;
	    @include transform(translate3d(0,-10px,15px) scale(1) /* rotateY(-3deg) */ rotateX(-2deg));
    }
}